import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { NiButtonRbComponent } from '@next-insurance/ni-material/ni-button-rb';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BannerType } from 'app/shared/components/alert-banner/banner-type.model';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaymentDetailsPerPolicy } from '../../../payment/models/payment-details-per-policy.model';
import { PaymentMethodDetails, PaymentMethodType } from '../../../payment/models/payment-method-details.model';
import { PaymentService } from '../../../payment/payment.service';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { AlertBannerComponent } from '../../../shared/components/alert-banner/alert-banner.component';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { AppState } from '../../../store';
import { FeatureFlags } from '../../models/feature-flags.enum';
import { InteractionType } from '../../models/interaction-type.enum';
import { BannersTrackingService } from '../../services/banners-tracking.service';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { ToastService } from '../../services/toast.service';
import { TrackingService } from '../../services/tracking.service';
import { LOCAL_STORAGE } from '../../tokens/local-storage.token';
import { SESSION_STORAGE } from '../../tokens/session-storage.token';

@Component({
  selector: 'ni-ach-recommendation-banner',
  templateUrl: './ach-recommendation-banner.component.html',
  styleUrls: ['./ach-recommendation-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AlertBannerComponent, NiButtonRbComponent, TranslateModule, NgIf],
})
export class AchRecommendationBannerComponent implements OnInit {
  isVisible$$ = signal<boolean>(false);
  private featureFlagsService = inject(FeatureFlagsService);
  private bannersTrackingService = inject(BannersTrackingService);
  private trackingService = inject(TrackingService);
  private paymentService = inject(PaymentService);
  private store = inject(Store<AppState>);
  private toastService = inject(ToastService);
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private sessionStorage: Storage = inject(SESSION_STORAGE);
  private destroyRef = inject(DestroyRef);

  readonly ButtonType = ButtonType;
  readonly BannerType = BannerType;
  private readonly bannerName = 'encourage-bank-payment-banner';
  private readonly isBannerHiddenForSessionKey = 'should-hide-ach-recommendation-banner';
  private readonly bannerClosingCounterKey = 'ach-recommendation-banner-closing-counter';

  ngOnInit(): void {
    if (this.featureFlagsService.isActive(FeatureFlags.AchRecommendationBanner)) {
      this.setBannerVisibility();
    }
  }

  onClose(): void {
    this.bannersTrackingService.trackCloseBannerClicked(this.bannerName);
    this.increaseClosingCounter();
    this.hideBannerForCurrentSession();
  }

  private setBannerVisibility(): void {
    this.shouldShowBanner().subscribe((shouldShowBanner: boolean) => {
      if (shouldShowBanner) {
        this.bannersTrackingService.trackViewBanner(this.bannerName);
        this.isVisible$$.set(true);
      } else {
        if (this.isVisible$$() === true) {
          this.bannersTrackingService.trackHideBanner(this.bannerName);
        }
        this.isVisible$$.set(false);
      }
    });
  }

  onSwitchNowClicked(): void {
    this.isVisible$$.set(false);
    this.trackSwitchNowClicked();
    this.hideBannerForCurrentSession();
    this.paymentService.openUpdatePaymentMethodModal({
      shouldCloseMobileBanner: true,
      onPaymentUpdateFinished: () => this.onPaymentUpdateFinished(),
    });
  }

  private onPaymentUpdateFinished(): void {
    this.toastService.showToast({
      toastType: ToastType.Success,
      message: `PAYMENT.UPDATE_PAYMENT_METHOD.SUCCESS_MESSAGE`,
    });
  }

  private hideBannerForCurrentSession(): void {
    this.sessionStorage.setItem(this.isBannerHiddenForSessionKey, 'true');
  }

  private increaseClosingCounter(): void {
    const numOfClosing = +localStorage.getItem(this.bannerClosingCounterKey);

    this.localStorage.setItem(this.bannerClosingCounterKey, (numOfClosing + 1).toString());
  }

  private trackSwitchNowClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.bannerName,
        name: 'switch-now-button',
      },
      true,
    );
  }

  private shouldShowBanner(): Observable<boolean> {
    return combineLatest([
      this.store.select(paymentSelectors.isLoading),
      this.store.select(paymentSelectors.getPaymentDetailsPerPolicy),
      this.store.select(policiesSelectors.hasOperativeNextPolicy),
      this.paymentService.isAchRecommendedMethod(),
      this.store.select(paymentSelectors.getPaymentMethodDetails),
    ]).pipe(
      filter(([isLoadingPayments]: [boolean, PaymentDetailsPerPolicy, boolean, boolean, PaymentMethodDetails]) => !isLoadingPayments),
      takeUntilDestroyed(this.destroyRef),
      map(
        ([, paymentDetailsPerPolicy, hasOperativeNextPolicy, isAchRecommendedMethod, paymentMethodDetails]: [
          boolean,
          PaymentDetailsPerPolicy,
          boolean,
          boolean,
          PaymentMethodDetails,
        ]) => {
          const earliestFailedPayment = this.paymentService.getEarliestFailedPayment(paymentDetailsPerPolicy);
          const numOfClosing = +this.localStorage.getItem(this.bannerClosingCounterKey);
          const isHiddenForCurrentSession = !!this.sessionStorage.getItem(this.isBannerHiddenForSessionKey);

          return (
            hasOperativeNextPolicy &&
            !earliestFailedPayment &&
            paymentMethodDetails &&
            paymentMethodDetails.type !== PaymentMethodType.BankAccount &&
            isAchRecommendedMethod &&
            numOfClosing < 3 &&
            !isHiddenForCurrentSession
          );
        },
      ),
    );
  }
}
